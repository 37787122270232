import { api, apiCancelable, vmDatacenter } from '@p/app/service/backend/index.ts'
import { type Getter } from '@p/app/types/Getter.ts'
import type { Item, ItemString, DataTableVmCluster, DataTableVmDatacenter, DataTableVmHost, DataTableVmNetwork, VmDatacenterEdit, TableCombined, TableQuery } from '@pitman/vm-api'

export function table (table: TableQuery): Getter<DataTableVmDatacenter> {
  return apiCancelable(vmDatacenter, 'vmDatacenterTable', table)
}

export function clusterTable (datacenterId: number, table: TableCombined): Getter<DataTableVmCluster> {
  return apiCancelable(vmDatacenter, 'vmDatacenterClusterTable', datacenterId, table)
}
export function clusterAutocomplete (datacenterId: number, table: TableCombined): Getter<Item[]> {
  return apiCancelable(vmDatacenter, 'vmDatacenterClusterAutocomplete', datacenterId, table)
}
export function hostTable (datacenterId: number, table: TableCombined): Getter<DataTableVmHost> {
  return apiCancelable(vmDatacenter, 'vmDatacenterHostTable', datacenterId, table)
}

export function clusterHostTable (clusterId: number, table: TableCombined): Getter<DataTableVmHost> {
  return apiCancelable(vmDatacenter, 'vmDatacenterClusterHostTable', clusterId, table)
}

export function networkTable (datacenterId: number, table: TableCombined): Getter<DataTableVmNetwork> {
  return apiCancelable(vmDatacenter, 'vmDatacenterNetworkTable', datacenterId, table)
}

export function tagAutocomplete (datacenterId: number): Getter<ItemString[]> {
  return apiCancelable(vmDatacenter, 'vmDatacenterTagAutocomplete', datacenterId)
}

export function networkAutocomplete (datacenterId: number, table: TableCombined): Getter<Item[]> {
  return apiCancelable(vmDatacenter, 'vmDatacenterNetworkAutocomplete', datacenterId, table)
}

export function networkAutocompleteFiltered (nodeId: number, datacenterId: number, table: TableCombined): Getter<Item[]> {
  return apiCancelable(vmDatacenter, 'vmDatacenterNetworkAutocompleteFiltered', nodeId, datacenterId, table)
}

export function datacenterAutocomplete (table: TableCombined): Getter<Item[]> {
  return apiCancelable(vmDatacenter, 'vmDatacenterAutocomplete', table)
}

export async function datacenterEdit (id: number, edit: VmDatacenterEdit): Promise<number> {
  return await api({ alert: true, task: true }, vmDatacenter, 'vmDatacenterEdit', id, edit)
}

export async function datacenterSync (): Promise<number> {
  return await api({ alert: true, task: true }, vmDatacenter, 'vmDatacenterSync')
}
