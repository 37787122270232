import { HttpException } from './NotFoundError.ts'
import { type Retrieve, type RetrieveMulti } from '../types/helper.ts'

export function retrieveCollector<T, K> (retrieve: RetrieveMulti<T, K>): Retrieve<T, K> {
  let curToDo: {
    ids: Set<K>
    controller: AbortController
    data: Promise<Map<K, T>>
    timeout: NodeJS.Timeout | null
    resolve: (value: Map<K, T>) => void
    reject: (reason: any) => void
  }

  reset()

  function call (): void {
    const todo = curToDo
    reset()
    if (todo.controller.signal.aborted) todo.reject(todo.controller.signal.reason ?? new DOMException('polyfill', 'AbortError'))
    retrieve([...todo.ids.values()], todo.controller.signal).then(e => { todo.resolve(new Map(e)) }, todo.reject)
  }

  function reset (): void {
    const newToDo: Partial<typeof curToDo> = {
      ids: new Set(),
      controller: new AbortController(),
      timeout: null
    }

    curToDo = Object.assign(newToDo, {
      data: new Promise<Map<K, T>>((resolve, reject) => {
        newToDo.resolve = resolve
        newToDo.reject = reject
      })
    }) as any
  }

  return async (id: K, signal: AbortSignal | undefined): Promise<Awaited<T>> => {
    signal?.throwIfAborted()
    const todo = curToDo
    if (todo.timeout === null) todo.timeout = setTimeout(call, 0)
    todo.ids.add(id)
    if (signal) {
      signal.onabort = () => {
        todo.ids.delete(id)
        if (todo.ids.size === 0) {
          todo.controller.abort()
          // timeout hasn't triggered
          if (todo === curToDo && todo.timeout !== null) {
            clearTimeout(todo.timeout)
            call()
          }
        }
      }
    }
    const res = await todo.data.then(e => e.get(id))
    signal?.throwIfAborted()
    if (res === undefined) throw new HttpException()
    return res
  }
}
