import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

export enum SSOMode {
  Hidden,
  Recommended,
  Required
}

export const ssoMode: SSOMode = (window as any)?.ssomode as SSOMode ?? SSOMode.Hidden
export const ssoUrl: string = (window as any)?.issuer as string
export const ssoClientId: string | null = (window as any)?.clientId as string || null

const openId = ssoClientId !== null
  ? new UserManager({
    authority: ssoUrl,
    client_id: ssoClientId!,
    redirect_uri: `https://${window.location.host}/login`,
    scope: 'openid offline_access urn:zitadel:iam:user:metadata',
    loadUserInfo: true,
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({
      store: localStorage
    })
  })
  : undefined

export default openId
