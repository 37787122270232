import type { EntityAdapter, EntityLinkAdapter } from '../types/helper.ts'

type CB = (add: Array<{ link: number, isDeep: boolean }>, del: number[]) => void

export class EntityLink<T, U = T> {
  readonly #t: EntityLinkAdapter<T, U>
  readonly #cache = new Map<number, { count: number, isDeep: boolean }>()

  #add: Array<{ link: number, isDeep: boolean }> = []
  #del: Array<{ link: number, isDeep: boolean }> = []
  #timeout: NodeJS.Timeout | null = null

  readonly #cbs: CB[] = []

  constructor (type: EntityLinkAdapter<T, U>) {
    this.#t = type

    const destroy = type.destroy
    type.destroy = (id, data) => {
      const link = this.#t.linkId(data)
      if (link !== null) this.minus(link)
      destroy?.(id, data)
    }

    const process = type.process
    type.process = (id, data, oldData, reason) => {
      const newData = process?.(id, data, oldData, reason) ?? data as any as U

      const newLink = this.#t.linkId(newData)
      const oldLink = this.#t.linkId(oldData)
      if (oldLink !== newLink) {
        if (newLink !== null) this.plus(newLink)
        if (oldLink !== null) this.minus(oldLink)
      }
      return newData
    }
  }

  minus (link: number): void {
    const el = this.#cache.get(link)
    if (el?.count === 1) {
      this.#cache.delete(link)
      this.#trigger(link, false)
    } else if (el !== undefined) this.#cache.set(link, { count: el.count - 1, isDeep: el.isDeep })
  }

  plus (link: number, isDeep: boolean = false): void {
    const el = this.#cache.get(link)
    if (el !== undefined) {
      const oldDeep = el.isDeep
      el.isDeep = isDeep || el.isDeep
      el.count = el.count + 1
      if (el.isDeep !== oldDeep) this.#trigger(link, true, el.isDeep)
    } else {
      this.#cache.set(link, { count: 1, isDeep })
      this.#trigger(link, true, isDeep)
    }
  }

  type (): EntityAdapter<T, U> {
    return this.#t
  }

  active (reset?: boolean): Array<{ link: number, isDeep: boolean }> {
    if (reset === true && this.#timeout !== null) {
      clearTimeout(this.#timeout)
      this.#timeout = null
      this.#add = []
      this.#del = []
    }
    return Array.from(this.#cache, x => ({ link: x[0], isDeep: x[1].isDeep }))
  }

  on (cb: CB): void {
    this.#cbs.push(cb)
  }

  #trigger (link: number, add: boolean, isDeep: boolean = false): void {
    const arr = add ? this.#add : this.#del
    const notArr = !add ? this.#add : this.#del
    // check if this exact link ist not already added or removed
    if (!arr.some(x => x.link === link && x.isDeep === isDeep)) {
      // check if a not deep link exists when new link is to deep, to remove existing link
      if (arr.some(x => x.link === link) && isDeep) arr.splice(arr.findIndex(x => x.link === link), 1)
      // add new link, except when the new link is not deep and a deep link already exists
      if (!arr.some(x => x.link === link)) arr.push({ link, isDeep })
    }
    if (notArr.some(x => x.link === link)) notArr.splice(notArr.findIndex(x => x.link === link), 1)
    if (this.#timeout === null) this.#timeout = setTimeout(this.#emit.bind(this), 0)
  }

  #emit (): void {
    this.#cbs.forEach(e => { e(this.#add, this.#del.map(x => x.link)) })
    this.#timeout = null
  }
}
