import { api, apiCancelable, vmIso } from '@p/app/service/backend/index.ts'
import { type Getter } from '@p/app/types/Getter.ts'
import type { DatastoreFolderList, Item, DataTableVmIso, DataTableVmIsoFolder, VmIsoFolderAdd, TableCombined, TableQuery } from '@pitman/vm-api'

export async function add (datastore: number, vmIsoFolderAdd: VmIsoFolderAdd): Promise<number> {
  return await api({ alert: true, task: true }, vmIso, 'vmIsoFolderAdd', datastore, vmIsoFolderAdd)
}

export async function folderList (datastoreId: number, folder: string): Promise<DatastoreFolderList> {
  return await api({ alert: true, task: false }, vmIso, 'vmIsoFolderFolderList', datastoreId, folder)
}

export function autocomplete (nodeId: number, data: TableCombined): Getter<Item[]> {
  return apiCancelable(vmIso, 'vmIsoAutocomplete', nodeId, data)
}
export function table (data: TableCombined): Getter<DataTableVmIso> {
  return apiCancelable(vmIso, 'vmIsoTable', data)
}

export async function remove (isofolderId: number): Promise<number> {
  return await api({ alert: true, task: true }, vmIso, 'vmIsoFolderRemove', isofolderId)
}
export async function pull (): Promise<number> {
  return await api({ alert: true, task: true }, vmIso, 'vmIsoPull')
}

export function folderTable (datastoreId: number, vmIsoFolderTable: TableQuery): Getter<DataTableVmIsoFolder> {
  return apiCancelable(vmIso, 'vmIsoFolderTable', datastoreId, vmIsoFolderTable)
}
